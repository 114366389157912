<template>
  <div class="profile">
    <form @submit.prevent="saveAll" class="container">
      <div class="profile__title">
        <h3><router-link :to="{ name: 'ProductList' }">{{$t('product.management')}}</router-link> / <span>{{$t('product.details')}}</span></h3>
      </div>
      <div class="box mg-b-10">
        <div class="title-bold mg-b-20">{{$t('product.edit')}}</div>

        <div class="title-gray mg-b-5">{{$t('product.type')}}</div>
        <div class="form-element">
          <select-input :value="{ value: product.type, label: $t(productType[product.type]) }" valueKey="value" labelKey="label" @select="(val) => onInput('type', val.value)" :options="productTypeOptionsi18n"/>
        </div>
        <div class="flex align-start justify-between mg-b-15 mg-t-15">
          <div class="flex-1">
            <checkbox :checked="product.one_time_purchase_only" @change="onInput('one_time_purchase_only', !product.one_time_purchase_only)" id="one_time_purchase_only">{{$t('product.one_time_purchase_only')}}</checkbox>
          </div>
        </div>

        <div class="title-gray mg-b-5 mg-t-15" v-if="product.type === 2">{{$t('product.promo_type')}}</div>
        <div class="form-element" v-if="product.type === 2">
          <select-input :value="product.promotion_type_obj" :valueKey="'value'" :labelKey="'label'"  @select="(val) => {
            onInput('promotion_type_obj', val)
            onInput('promotion_type', val.value)
          }" :options="promoTypeOptionsi18n"/>
        </div>

<!--        <div class="title-gray mg-b-5 mg-t-15" v-if="product.system_id">{{$t('product.number')}}</div>-->
<!--        <div class="form-element" v-if="product.system_id">-->
<!--          <input type="text" v-model="product.system_id" readonly class="form-control"/>-->
<!--        </div>-->

        <div class="title-gray mg-b-5 mg-t-15">{{$t('product.name')}}</div>
        <div class="form-element">
          <input type="text" required v-model="product.name" class="form-control"/>
        </div>

        <div class="title-gray mg-b-5 mg-t-15">{{$t('product.name_c')}}</div>
        <div class="form-element">
          <input type="text" required v-model="product.name_c" class="form-control"/>
        </div>

        <div class="title-gray mg-b-5 mg-t-15">{{$t('product.original_price')}}</div>
        <div class="form-element with-symbol">
          <div class="form-symbol">$</div>
          <input type="number" step="0.01" required v-model="product.original_price" class="form-control"/>
        </div>

        <div class="title-gray mg-b-5 mg-t-15" v-if="product.type !== 2 && product.type !== 3">{{$t('product.price')}}</div>
        <div class="form-element with-symbol" v-if="product.type !== 2 && product.type !== 3">
          <div class="form-symbol">$</div>
          <input type="number" step="0.01" v-model="product.sell_price" :max="product.original_price-0.01 || 0" class="form-control"/>
        </div>

        <div class="mg-t-15" v-if="product.type !== 1 && product.type !== 4">
          <div class="flex mg-t-15 mg-b-15 align-start justify-start">
            <div class="flex-50" v-if="product.type === 2">
              <div class="title-gray mg-b-5">{{$t('product.promo_price')}}</div>
              <div class="form-element with-symbol w-125">
                <div class="form-symbol">$</div>
                <input required type="number" step="0.01" :value="calculatePromotionPrice" :disabled="product.promotion_type !== 5" @input="($event) => onInput('promotion_price', $event.target.value)" class="form-control"/>
              </div>
            </div>
            <div class="flex-50" v-if="product.type === 2">
              <div class="title-gray mg-b-5">{{$t('product.promotion_rate')}}</div>
              <div class="form-element with-symbol w-125">
                <div class="form-symbol">%</div>
                <input required type="number" :value="calculatePromotionRate" :disabled="product.promotion_type !== 4" step="0.01" @input="($event) => onInput('promotion_rate', $event.target.value)" class="form-control"/>
              </div>
            </div>
          </div>
          <div class="flex mg-t-15 mg-b-15 align-start justify-start">
            <div class="flex-50" v-if="product.type === 3">
              <div class="title-gray mg-b-5">{{$t('product.negotiated_price')}}</div>
              <div class="form-element with-symbol w-125">
                <div class="form-symbol">$</div>
                <input required type="number" step="0.01" class="form-control" v-model="product.negotiated_price"/>
              </div>
            </div>
            <div class="flex-50" v-if="product.type === 3">
              <div class="title-gray mg-b-5">{{$t('product.points')}}</div>
              <div class="form-element w-125">
                <input required type="number" step="0.1"  class="form-control" v-model="product.points"/>
              </div>
            </div>
          </div>
          <div class="flex mg-t-15 mg-b-15 align-start justify-start">
            <div class="flex-50">
              <div class="title-gray mg-b-5">{{$t('product.sales_start')}}</div>
              <div class="form-element with-icon w-175">
                <datepicker v-model="product.sale_starts_at" class="form-control"/>
                <img src="/images/icons/calendar.png" class="form-icon"/>
              </div>
            </div>
            <div class="flex-50">
              <div class="title-gray mg-b-5">{{$t('product.sales_end')}}</div>
              <div class="form-element with-icon w-175">
                <datepicker v-model="product.sale_ends_at" class="form-control"/>
                <img src="/images/icons/calendar.png" class="form-icon"/>
              </div>
            </div>
          </div>
          <div class="flex mg-t-15 mg-b-15 align-start justify-start">
            <div class="flex-50">
              <div class="title-gray mg-b-5">{{$t('product.redemption_start')}}</div>
              <div class="form-element with-icon w-175">
                <datepicker v-model="product.redemption_starts_at" class="form-control"/>
                <img src="/images/icons/calendar.png" class="form-icon"/>
              </div>
            </div>
            <div class="flex-50">
              <div class="title-gray mg-b-5">{{$t('product.redemption_end')}}</div>
              <div class="form-element with-icon w-175">
                <datepicker v-model="product.redemption_ends_at" class="form-control"/>
                <img src="/images/icons/calendar.png" class="form-icon"/>
              </div>
            </div>
          </div>
        </div>

        <div class="title-gray mg-b-5 mg-t-15">{{$t('product.description')}} <span>{{$t('product.three_lines_max')}}</span></div>
        <div class="form-element">
          <textarea required v-model="product.description" @keypress="descriptionKeyPress" class="form-control"/>
        </div>

        <div class="title-gray mg-b-5 mg-t-15">{{$t('product.description_c')}} <span>{{$t('product.three_lines_max')}}</span></div>
        <div class="form-element">
          <textarea required v-model="product.description_c" @keypress="descriptionKeyPress" class="form-control"/>
        </div>

        <div class="flex align-center justify-between mg-t-20 mg-b-10">
          <div class="flex-50">
            <div class="title-gray">{{$t('product.photo')}}</div>
          </div>
        </div>
        <image-select :default-value="product.avatar ? [product.avatar] : []" @selected="(data) => onInput('avatar', data.images[0])"/>

        <div class="title-gray mg-b-5">{{$t('product.disclaimer')}} <span>{{$t('product.fifteen_lines_max')}}</span></div>
        <div class="form-element">
          <textarea required v-model="product.disclaimer" @keypress="disclaimerKeyPress" class="form-control"/>
        </div>

        <div class="title-gray mg-t-15 mg-b-5">{{$t('product.terms')}}</div>
        <div class="subtitle-gray mg-b-10">{{$t('product.terms_sub')}}</div>
        <div class="subtitle-gray mg-b-10">{{$t('product.terms_sub_2')}}</div>
        <div class="flex align-center justify-between" v-if="product.terms">
          <a target="_blank" :href="product.terms" class="flex align-center justify-start pointer">
            <img src="/images/icons/folder.png"/>
            <span class="red mg-l-10 underline">{{$t('product.view')}}</span>
          </a>
          <div class="flex-33">
            <button class="btn btn--red" @click="product.terms = null"><i class="fas fa-trash-alt"/> {{$t('global.delete')}}</button>
          </div>
        </div>
        <input type="file" class="file-input" ref="fileInput" accept=".xlsx,.xls,image/*,.doc,.docx,.ppt,.pptx,.txt,.pdf,.mp4,.mkv,.webm" @change="uploadFile"/>
        <div class="profile__upload" @click="$refs.fileInput.click()"><i class="fas fa-link"/> {{product.terms ? $t('global.update_file') : $t('global.upload_file')}}</div>
      </div>

      <div class="box mg-b-10">
        <div class="title-gray mg-b-5">{{$t('product.merchant_edit_date')}}</div>
        <div class="text-dark">{{product.merchant_updated_at ? $filters.formatTimestamp(product.merchant_updated_at) : '-'}}</div>
        <div class="title-gray mg-t-15 mg-b-5">{{$t('product.merchant_edit_email')}}</div>
        <div class="text-dark">{{product.merchant_updated_by ? product.merchant_updated_by.email : '-'}}</div>
        <div class="title-gray mg-t-15 mg-b-5">{{$t('product.admin_approve_date')}}</div>
        <div class="text-dark">{{product.approved_at ? $filters.formatTimestamp(product.approved_at) : '-'}}</div>
        <div class="title-gray mg-t-15 mg-b-5">{{$t('product.admin_approve_email')}}</div>
        <div class="text-dark">{{product.approved_by ? product.approved_by.email : '-'}}</div>
        <div class="title-gray mg-t-15 mg-b-5">{{$t('product.status')}}</div>
        <div class="text-dark" v-if="productStatus[product.status]">{{$t(productStatus[product.status])}}</div>
        <div class="mg-t-30 flex justify-center">
          <button class="btn btn--red w-200" type="submit">{{$t('global.save')}}</button>
        </div>
      </div>
    </form>

    <div class="box preview">
      <div class="flex justify-between align-center title-bold mg-b-20">{{$t('product.preview')}} <lang-switch :language="prevLang" @langSelect="changeLang"/></div>

      <div class="title-gray mg-b-20">{{$t('product.page_list')}}</div>

      <PreviewBig :options="previewOptions"/>

      <div class="title-gray mg-b-20 mg-t-20">{{$t('product.grid_list')}}</div>

      <PreviewGrid :options="previewOptions"/>

      <div class="title-gray mg-b-20 mg-t-20">{{$t('product.list')}}</div>

      <PreviewList :options="previewOptions"/>
    </div>
    <Loading v-if="isLoading"/>
  </div>
</template>

<script>

import PreviewBig from '@/components/previews/previewBig'
import PreviewGrid from '@/components/previews/previewGrid'
import Checkbox from '@/components/checkbox/checkbox'
import PreviewList from '@/components/previews/previewList'
import Select from '@/components/select/select'
import imageSelect from '@/components/imageSelect/imageSelect'
import Datepicker from 'vue3-datepicker'
import _ from 'lodash'
import moment from 'moment-timezone'
import { Product, Upload } from '@/api/api'
import Loading from '@/components/loading/Loading'
import { promoTypeOptions, promoTypes, productStatus, productType, productTypeOptions } from '@/config/config'
import LangSwitch from '@/components/langSwitch/langSwitch'
export default {
  name: 'OrderDetails',
  components: {
    LangSwitch,
    Checkbox,
    Loading,
    SelectInput: Select,
    PreviewList,
    Datepicker,
    PreviewGrid,
    imageSelect,
    PreviewBig
  },
  data () {
    return {
      storeId: 0,
      productId: 0,
      product: {
        type: 1,
        store: {},
        updated_by: {},
        approved_by: {}
      },
      prevLang: 'en',
      isNew: false,
      isLoading: true
    }
  },
  mounted () {
    if (this.$route.params.id) {
      const ids = this.$route.params.id.split('-')

      if (ids.length === 2) {
        this.storeId = ids[1]
        this.productId = ids[0]

        if (this.productId !== 'new') {
          this.fetchProduct()
        } else {
          this.isNew = true
          this.isLoading = false
        }
      }
    }
  },
  computed: {
    calculatePromotionPrice () {
      if (this.product.promotion_type == 1) {
        return _.round((this.product.original_price || 0) * 0.5, 2)
      } else if (this.product.promotion_type == 2) {
        return _.round((this.product.original_price || 0) * 0.75, 2)
      } else if (this.product.promotion_type == 3) {
        return _.round((this.product.original_price || 0) * 2 / 3, 2)
      } else if (this.product.promotion_type == 4) {
        return _.round((this.product.original_price || 0) * ((this.product.promotion_rate || 0)), 2)
      } else {
        return this.product.promotion_price
      }
    },
    calculatePromotionRate () {
      if (this.product.promotion_type == 1) {
        return 0.5
      } else if (this.product.promotion_type == 2) {
        return 0.75
      } else if (this.product.promotion_type == 3) {
        return 0.67
      } else if (this.product.promotion_type == 4) {
        return this.product.promotion_rate
      } else {
        const price = this.product.original_price || 0
        const promo = this.product.promotion_price || 0

        if (price > 0) {
          return _.round(promo / price, 2)
        } else {
          return 0
        }
      }
    },
    productStatus () {
      return productStatus
    },
    promoTypeOptionsi18n () {
      return _.cloneDeep(promoTypeOptions).map((o) => {
        o.label = this.$t(o.label)
        return o
      })
    },
    productTypeOptionsi18n () {
      return _.cloneDeep(productTypeOptions).map((o) => {
        o.label = this.$t(o.label)
        return o
      })
    },
    promoTypes () {
      return promoTypes
    },
    productType () {
      return productType
    },
    previewOptions () {
      return {
        title: this.prevLang === 'en' ? this.product.name : this.product.name_c,
        avatar: this.product.avatar,
        description: this.prevLang === 'en' ? this.product.description : this.product.description_c,
        price: this.product.type === 2 ? this.product.promotion_price : this.product.sell_price,
        price_original: this.product.original_price,
        hasPoints: this.product.type === 3,
        points: this.product.points,
        disclaimer: this.product.disclaimer,
        hasPromo: this.product.type === 2,
        promotion_price: this.product.promotion_price,
        promotion_rate: this.product.promotion_rate,
        promotion_type: this.product.promotion_type,
        merchant_name: this.prevLang === 'en'
          ? this.$store.getters['auth/me'].store.company + ' - ' + this.$store.getters['auth/me'].store.district.name
          : this.$store.getters['auth/me'].store.company_c + ' - ' + this.$store.getters['auth/me'].store.district.name_c
      }
    }
  },
  watch: {
    calculatePromotionPrice (newVal) {
      this.product.promotion_price = newVal
    },
    calculatePromotionRate (newVal) {
      this.product.promotion_rate = newVal
    }
  },
  methods: {
    changeLang (val) {
      this.prevLang = val
    },
    onInput (key, value) {
      if (key === 'promotion_rate' && value >= 1) {
        value *= 0.01
      }

      this.product = {
        ...this.product,
        [key]: value
      }

      if (key === 'promotion_type' && (value === 4 || value === 5)) {
        this.product.promotion_price = 0
        this.product.promotion_rate = 0
      }
    },
    descriptionKeyPress (e) {
      if (e.keyCode === 13) {
        const lineBreaks = (e.target.value.match(/(?:\r\n|\r|\n)/g) || []).length

        if (lineBreaks === 99) {
          e.preventDefault()
        }
      }
    },
    disclaimerKeyPress (e) {
      if (e.keyCode === 13) {
        const lineBreaks = (e.target.value.match(/(?:\r\n|\r|\n)/g) || []).length

        if (lineBreaks === 99) {
          e.preventDefault()
        }
      }
    },
    async uploadFile (event) {
      const file = event.target.files[0]

      this.isLoading = true

      try {
        const resp = await Upload.file(file)

        this.product.terms = resp.data
      } catch (err) {
        this.$toast.error(this.$t('product.upload_fail'))
      } finally {
        this.isLoading = false
      }
    },
    async fetchProduct () {
      this.isLoading = true

      try {
        const resp = await Product.getOne(this.storeId, this.productId)

        if (resp.data.promotion_type) {
          resp.data.promotion_type_obj = {
            value: resp.data.promotion_type,
            label: this.$t(promoTypes[resp.data.promotion_type])
          }
        }

        this.product = {
          ...resp.data,
          redemption_starts_at: resp.data.redemption_starts_at ? moment(resp.data.redemption_starts_at).toDate() : null,
          redemption_ends_at: resp.data.redemption_ends_at ? moment(resp.data.redemption_ends_at).toDate() : null,
          sale_starts_at: resp.data.sale_starts_at ? moment(resp.data.sale_starts_at).toDate() : null,
          sale_ends_at: resp.data.sale_ends_at ? moment(resp.data.sale_ends_at).toDate() : null
        }
      } catch (err) {
        this.$toast.error(this.$t('product.load_fail'))
      } finally {
        this.isLoading = false
      }
    },
    async saveAll () {
      if (this.product.status === 3) {
        this.$toast.warning(this.$t('product.unpublish_first'))

        return
      }

      if (!this.product.avatar || !this.product.avatar.length) {
        this.$toast.error(this.$t('product.img_error'))

        return
      }

      if (!this.product.terms || !this.product.terms.length) {
        this.$toast.error(this.$t('product.terms_error'))

        return
      }

      this.isLoading = true

      try {
        const p = { ...this.product }

        delete p.approved_by
        delete p.updated_by

        const zone = moment.tz.guess()

        p.redemption_starts_at = p.redemption_starts_at ? moment.tz(p.redemption_starts_at, zone).format('YYYY-MM-DD') : undefined
        p.redemption_ends_at = p.redemption_ends_at ? moment.tz(p.redemption_ends_at, zone).format('YYYY-MM-DD') : undefined
        p.sale_starts_at = p.sale_starts_at ? moment.tz(p.sale_starts_at, zone).format('YYYY-MM-DD') : undefined
        p.sale_ends_at = p.sale_ends_at ? moment.tz(p.sale_ends_at, zone).format('YYYY-MM-DD') : undefined

        if (this.isNew) {
          await Product.createOne(this.storeId, p)
        } else {
          await Product.updateOne(this.storeId, this.productId, p)
        }

        this.$toast.success(this.$t('product.update_success'))
        this.$router.push({ name: 'ProductList' })
      } catch (err) {
        this.$toast.error(this.$t('product.update_fail'))
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
  @import "style.scss";
</style>
